import Section, { SectionType } from '../Section';
import { EventType } from './interfaces';

export default class CalendarSection extends Section {
  events: EventType[] = [];

  constructor(props: any) {
    super(props);

    this.type = SectionType.CALENDAR;
    this.events = props.events;
    this.actionButtonTxtKey = 'view';
    this.alertProps.error.txtKey = 'alert.fetchFailed';
    this.alertProps.noItems.txtKey = 'alert.noCalendarEvents';
  }
}
